<template>
  <div>
    <div class="multiple-choice-button-wrapper">
      <p class="title-label">
        {{ label }} <span class="sublabel">{{ subLabel }}</span>
      </p>
      <div class="multiple-choice-button-list">
        <template v-for="(checkbox, index) in data">
          <div :key="index">
            <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
            <label
              class="app-input-checkbox"
              :class="{ disabled }"
              role="checkbox"
              :tabindex="index"
              :aria-checked="checkbox.value"
              :aria-disabled="checkbox.disabled"
            >
              <input
                v-model="selectedValues"
                :value="checkbox.value"
                class="check-input"
                type="checkbox"
                @click="toggleSelection(checkbox)"
              />
              <AppRipple class="halo" is-centered :is-disabled="disabled">
                <span class="checkbox">
                  <AppIcon class="icon" color="white" size="12">
                    <IconCheckmarkFat />
                  </AppIcon>
                </span>
              </AppRipple>
              <div class="label">
                {{ checkbox.text }}
              </div>
            </label>
          </div>
        </template>
      </div>
    </div>
    <div class="multiple-choice-button-wrapper">
      <AppInputText
        v-if="isTextBoxShown"
        v-model="customInputText"
        type="text"
        :label="customInputLabel"
        :placeholder="customInputPlaceholder"
        :validation="validation"
        @input="onCustomInputChange"
      />
    </div>
  </div>
</template>

<script>
import AppInputText from '@/components/AppInputText/AppInputText'
import { ref, computed } from '@vue/composition-api'
import { IconCheckmarkFat } from '@moneytransfer.ui/euronet-icons'
import AppIcon from '@/components/AppIcon/AppIcon.vue'

export default {
  name: 'XeMultipleChoiceButtonsWithCustomInput',
  components: {
    AppInputText,
    AppIcon,
    IconCheckmarkFat,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    subLabel: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [Array, String],
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const isTextBoxShown = ref(false)
    const customInputLabel = ref('')
    const customInputText = ref('')
    const customInputPlaceholder = ref('')

    let selectedValues = ref([...props.value])

    let isCustomInput = typeof props.value === 'string'

    const toggleSelection = (option) => {
      isTextBoxShown.value = false

      const index = selectedValues.value.indexOf(option.value)
      if (index !== -1) {
        // Remove selected if found
        selectedValues.value.splice(index, 1)
      } else {
        // Add selected if not found
        selectedValues.value.push(option.value)
      }

      // Get selectOptions for groupId check
      const selectedOptions = props.data.filter((eachOption) =>
        selectedValues.value.includes(eachOption.value)
      )
      const isDifferentGroupSelected = selectedOptions.some((selected) => {
        return selected.groupId !== option.groupId
      })
      // Clear selection if different group is selected
      if (isDifferentGroupSelected) {
        selectedValues.value = []
        selectedValues.value.push(option.value)
      }

      // Show textbox if customInput option is selected
      if (option.allowTextBox && selectedValues.value.length > 0) {
        isTextBoxShown.value = true
        customInputLabel.value = option.freeTextLabel
        customInputPlaceholder.value = option.freeTextPlaceholder
        return onCustomInputChange('')
      }

      emit('input', selectedValues.value)
    }

    const isSelected = (option) => {
      // handle custom input
      if (option.allowTextBox && typeof props.value === 'string') {
        return true
      }
      return props.value.includes(option.value)
    }

    const onCustomInputChange = (text) => {
      customInputText.value = text
      if (!text) {
        return emit('input', '')
      }
      emit('input', text)
    }

    // reset custom input on first land if custom input is selected
    if (isCustomInput) {
      const customInputOption = props.data.find((eachOption) => eachOption.allowTextBox)
      selectedValues.value = [customInputOption.value]
      isTextBoxShown.value = true
      onCustomInputChange(props.value)
    }

    return {
      selectedValues,
      toggleSelection,
      isSelected,
      isTextBoxShown,
      customInputLabel,
      customInputText,
      customInputPlaceholder,
      onCustomInputChange,
    }
  },
}
</script>

<style lang="postcss" scoped>
.app-input-checkbox {
  @apply relative flex items-center gap-1;
  input[type='checkbox'] {
    @apply hidden;
  }
  .halo {
    @apply inline-flex justify-center items-center w-6 h-6 rounded-sm bg-transparent;
    &:after {
      content: '';
      @apply absolute w-6 h-6 bg-gray-lighter rounded-sm;
      @apply transform scale-0 transition-transform ease-in-out duration-180;
    }
    .md-ripple-enter {
      @apply opacity-100;
    }
    .md-ripple-wave {
      @apply bg-gray-lighter;
    }
    .checkbox {
      @apply inline-flex justify-center items-center w-5 h-5 border-2 rounded-sm border-text-gray-secondary z-10;
      .icon {
        @apply opacity-0 transition-opacity duration-180;
      }
    }
  }
  input[type='checkbox']:checked ~ .halo {
    &:after {
      @apply bg-orange-lighter;
    }
    .md-ripple-wave {
      @apply bg-orange-lighter;
    }
    .checkbox {
      @apply bg-orange border-orange;
      .icon {
        @apply opacity-100;
      }
    }
  }
  input[type='checkbox']:disabled ~ .halo {
    &:after {
      @apply hidden;
    }
    .checkbox {
      @apply border-gray-light;
    }
  }
  input[type='checkbox']:checked:disabled ~ span {
    @apply bg-gray-light border-transparent;
  }
  &:hover .halo:after,
  &:focus .halo:after {
    @apply scale-100;
  }
  &.disabled {
    @apply cursor-not-allowed;
  }
  &:focus {
    @apply outline-none;
  }
}
.multiple-choice-button-wrapper {
  @apply mb-6;
  .title-label {
    @apply mb-1;
    .sublabel {
      color: #888f9f;
    }
  }
  .multiple-choice-button-list {
    @apply flex items-start flex-col gap-4;

    @screen sm {
      @apply flex-row gap-8;
    }

    .multiple-choice-button {
      border-color: #006ce0 !important;
      @apply w-full rounded-md !important;
      &.active {
        background-color: #006ce0 !important;
        @apply text-light;
      }
      /deep/ .button-inner {
        @apply px-3 !important;
      }
    }
  }
}
</style>
